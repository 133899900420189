.page-horizontal-bar.page-header-fixed .horizontal-bar { padding-top: 40px !important; }

.navbar { height: 40px !important; min-height: 40px !important; }
.navbar .logo-box { height: 40px !important; width: 200px !important; }
.navbar .logo-box .logo-text { line-height: 40px !important; padding: 0 15px !important; text-align: left !important; }
.navbar .topmenu-outer { height: 40px !important; }
.navbar .top-menu .navbar-nav > li { height: 50px !important; }
.navbar .top-menu .navbar-nav > li > a { padding: 10px 18px !important; }
.navbar .top-menu .navbar-nav > span > li { height: 50px !important; }
.navbar .top-menu .navbar-nav > span > li > a { padding: 15px 18px !important; }

.menu { background: #22baa0 !important; }
.menu.accordion-menu > li.droplink:hover > a { background: #1db198 !important; }
.menu.accordion-menu a { color: white !important; }
.menu.accordion-menu ul { background: #1db198 !important; }
.menu.accordion-menu ul li a:hover { background: #22baa0 !important; }

.compact-menu .small-sidebar.page-horizontal-bar .horizontal-bar .accordion-menu > li > a, .compact-menu .horizontal-bar .accordion-menu > li > a { padding: 12px 16px !important; }
.compact-menu .horizontal-bar .accordion-menu > li > ul { top: 42px !important; }

.sidebar { background: #22baa0 !important; }